/** @format */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 1px;
}

html,
body {
  letter-spacing: 1px;
  overflow-x: hidden;
  padding-bottom: 0;
}

@font-face {
  font-family: "Romantica Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Romantica Regular"), url(/static/media/Romantica-RpXpW.3cd8e213.woff) format("woff");
}

.navbar-light .navbar-toggler-icon {
  background-image: url(/static/media/menu-icon.52223e71.png);
  height: 30px;
  width: 30px;
  margin-top: -1em;
}

.navbar-light .navbar-nav .nav-link {
  background-color: rgba(0, 0, 0, 1);
}
.content {
  min-height: 100vh;
  width: 100%;
}

.welcome {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-family: "Romantica Regular";
  color: #fff;
  font-size: 4em;
  font-weight: 900;
  letter-spacing: 3px;
}

.recipeimg {
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
}
.recipe {
  /* width: 80%; */
  line-height: 2em;
  padding-top: 1em;
  /* height: 4em; */
}
/*Homepage and titles*/
.choc {
  color: rgb(255, 102, 0);
}
.mh {
  margin-top: 0em;
}
/*Homepage and titles*/
/*My @medias*/

@media only screen and (max-width: 320px) {
}
@media only screen and (max-width: 360px) {
  .mylogo {
    margin-top: -17px;
  }
  .abus {
    line-height: 1.5em;
  }
  .recipe {
    /* width: 80%; */
    line-height: 1.5em;
    padding-top: 0.5em;
    /* height: 4em; */
  }
  .choc {
    color: rgb(255, 102, 0);
    padding-top: 1em;
  }
  .welcome {
    position: absolute;
    top: 47%;
    left: 54%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-family: "Romantica Regular";
    color: #fff;
    font-size: 4em;
    font-weight: 900;
    letter-spacing: 3px;
    line-height: 1.5em;
  }
  .mh {
    margin-top: 3em;
  }
  .self {
    line-height: 2em;
    font-size: 23px;
  }
  .ess {
    color: #fff;
    text-align: center;
    font-weight: bold;
    letter-spacing: 5px;
    padding-bottom: 1em;
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .mylogo {
    margin-top: -17px;
  }
  .welcome {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-family: "Romantica Regular";
    color: #fff;
    font-size: 4em;
    font-weight: 900;
    letter-spacing: 3px;
    width: 100%;
    text-align: center;
  }
  .self {
    line-height: 2em;
    font-size: 20px;
  }
  .ess {
    color: #fff;
    text-align: center;
    font-weight: bold;
    letter-spacing: 5px;
    padding-bottom: 1em;
    font-size: 25px;
  }
}

@media only screen and (max-width: 800px) {
  .mylogo {
    margin-top: -17px;
  }
  .welcome {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-family: "Romantica Regular";
    color: #fff;
    font-size: 4em;
    font-weight: 900;
    letter-spacing: 3px;
    width: 100%;
    text-align: center;
  }
  .self {
    line-height: 2em;
    font-size: 20px;
  }
  .ess {
    color: #fff;
    text-align: center;
    font-weight: bold;
    letter-spacing: 5px;
    padding-bottom: 1em;
    font-size: 25px;
  }
}
/*My @medias*/

/*Nav*/
nav {
  display: -webkit-flex;
  display: flex;
  height: 6em;
  background-color: rgba(14, 14, 14, 0.64);
}

.mylogo {
  width: 15vh;
  float: left;
  color: #fff !important;
  border: 3px solid #f60;
  text-align: center;
  font-size: 1.7em;
  text-decoration: none !important;
}
.mynav {
  color: #fff !important;
  background: rgba(0, 0, 0, 1);
  float: left;
  position: absolute;
  height: 100px;
  padding: 2em;
}
.mymenu {
  font-family: "Romantica Regular";
  color: #fff !important;
  font-size: 1.5em;
  font-weight: 900;
  border-bottom: 2px transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
}

.mymenu:hover {
  /* color: #fff;
  font-size: 19px; */
  background-color: transparent;
  text-decoration: none;
  border-bottom: 2px solid #e95c31;
}
.active {
  border-bottom: 2px solid #e95c31;
}

/*Nav*/

/*Header*/
.myheader {
  background: rgba(14, 14, 14, 0.64);
  position: absolute;
  height: 100px;
  width: 100vw;
}

.mybanner_one {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),
    url(/static/media/donuts.5c6d73b6.jpg) no-repeat 0px 0px;
  background-size: auto;
  background-size: cover;
  min-height: 100vh;
}
.mybanner_two {
  background: url(/static/media/d2.41e50326.jpg) no-repeat 0px 0px;
  background-size: auto;
  background-size: cover;
  min-height: 30vh;
}
/*Header*/

/*Footer*/
.myfooter {
  width: 100%;
  background-color: #ff6600;
  color: white;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: -1em;
}

.foot {
  padding: 1em;
  color: #fff;
}
/*Footer*/

/*My Headings*/
.mytitles {
  height: 2em;
  letter-spacing: 1px;
  color: black;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  margin-bottom: 1em;
  padding-bottom: 0.5em;
  width: 100%;
  padding-top: 1em;
}

.mytitles::after {
  background-color: #ff6600;
  bottom: 0;
  content: "";
  display: block;
  height: 3px;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  width: 100px;
}
/*My Headings*/

/*Buttons*/
.mybutton {
  background-color: rgb(59, 176, 230);
  border: none;
  color: white;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px 2px;
  cursor: pointer;
  letter-spacing: 1px;
  width: 100%;
  margin-bottom: 3em;
}

.mybutton:hover {
  background-color: #ff6600;
  color: #fff;
  transition: 1s;
}
.rbut {
  width: 100%;
  line-height: 2em;
}
/*Buttons*/

/*Contact*/
.con {
  letter-spacing: 1px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #fff;
  padding: 0.5em;
  background: transparent;
  color: #fff;
}
.con_2 {
  letter-spacing: 1px;
  width: 100%;
  border: none;
  border: 1px solid #fff;
  padding: 0.5em;
  letter-spacing: 1px;
  min-height: 150px;
  background: transparent;
  color: #fff;
}
.con_3 {
  width: 100%;
  border: 1px solid #fff;
  padding: 0.5em;
  letter-spacing: 1px;
  background: transparent;
  color: #fff;
}
.contactback {
  background: url(/static/media/contact.0aae2b9f.png) no-repeat 0px 0px;
  background-size: auto;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  color: #fff;
  margin-top: 3em;
}
.transparentform {
  background: rgba(14, 14, 14, 0.9);
  padding-top: 3rem;
  margin-bottom: 3rem;
}
.ess {
  color: #fff;
  text-align: center;
  font-weight: bold;
  letter-spacing: 5px;
  padding-bottom: 1em;
}
/*Contact*/

/*Order*/
.order {
  background: url("https://images.unsplash.com/photo-1470124182917-cc6e71b22ecc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80")
    no-repeat 0px 0px;
  background-size: auto;
  -webkit-background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  color: #fff;
}
.self {
  line-height: 2em;
}
.einfo {
  text-align: center;
  line-height: 2em;
}
/*order*/

/*about*/
.abus {
  width: 85%;
  line-height: 2em;
}
/*about*/

/*Gallery*/
.galimages {
  height: 15em;
  max-width: 100% !important;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
}

.ha {
  margin-bottom: 4em;
}

.nam {
  height: 5em;
  padding-top: 1em;
}
/*Gallery*/
/*Shopping Cart*/
.add {
  background-color: #ff6600;
  border: none;
  color: white;
  padding: 10px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px 2px;
  cursor: pointer;
  letter-spacing: 1px;
  width: 100%;
  margin-bottom: 3em;
}

.add:hover {
  background-color: black;
  color: #fff;
  transition: 1s;
}
/*Shopping Cart*/

